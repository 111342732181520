import { useCallback, useContext, useState } from 'react';
import { incomingInvoice as incomingInvoiceApi } from 'utilities/api';
import { Context } from 'components/Store';
import { useToast } from '../../../components/ToastContainer';
import useApiCall from '../helpers/useIntegrationApiHelpers';

const useIncomingInvoiceApi = () => {
  const [loading, setLoading] = useState(false);
  const [, dispatch] = useContext(Context);
  const orgId = localStorage.getItem('selectedOrganizationId');

  const apiCall = useApiCall({
    setLoading,
    toast: useToast(),
    dispatch
  });

  const listIncomingInvoices = useCallback(
    params => {
      const { promise } = incomingInvoiceApi
        .createChildApi({
          action: 'integrations/incoming-invoice'
        })
        .fetch(null, params, {
          headers: {
            'cwf-context': JSON.stringify({
              organization_id: orgId
            })
          }
        });

      setLoading(true);

      return apiCall(promise, {
        errorTitle: 'Unable to list Invoices',
        errorContent:
          'An error prevented to list existing Incoming Invoices. Please try again.'
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgId, apiCall]
  );

  const getMatches = useCallback(
    ({ vendor, matchTypes = [] }) => {
      const { promise } = incomingInvoiceApi
        .createChildApi({
          action: 'integrations/incoming-invoice/match'
        })
        .fetch(
          null,
          {
            vendor,
            types: matchTypes.join(',')
          },
          {
            headers: {
              'cwf-context': JSON.stringify({
                organization_id: orgId
              })
            }
          }
        );

      setLoading(true);

      return apiCall(promise, {
        errorTitle: 'Unable to retrieve matches',
        errorContent:
          'An error prevented to retrieve matches. Please try again.'
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgId, apiCall]
  );

  const createMatches = useCallback(
    ({ vendor, matches }) => {
      const { promise } = incomingInvoiceApi
        .createChildApi({
          action: 'integrations/incoming-invoice/match'
        })
        .create(
          matches.map(match => ({ ...match, vendor })),
          {
            headers: {
              'cwf-context': JSON.stringify({
                organization_id: orgId
              })
            }
          }
        );

      setLoading(true);

      return apiCall(promise, {
        errorTitle: 'Unable to create matches',
        errorContent: 'An error prevented to create match. Please try again'
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgId, apiCall]
  );

  return {
    listIncomingInvoices,
    createMatches,
    getMatches,
    loading
  };
};

export default useIncomingInvoiceApi;
