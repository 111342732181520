import React, { useContext } from 'react';

import AgVendInvoiceList from '../AgVendInvoiceList';
import { AgVendContext } from '../context/Provider';
import AgVendMatches from '../AgVendMatches';

const STEPS = {
  1: <AgVendInvoiceList />,
  2: <AgVendMatches />,
  3: <div>Review And Import</div>
};

const StepSelector = () => {
  const {
    state: { step }
  } = useContext(AgVendContext);

  return STEPS[step];
};

export default StepSelector;
