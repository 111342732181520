import catchCancel from 'helpers/catchCancel';
import { createServerError, parseToastServerError } from 'helpers/errorHelpers';

function useApiCall({ setLoading, toast, dispatch }) {
  return (promise, { errorTitle, errorContent }) =>
    promise
      .then(res => res)
      .catch(catchCancel)
      .catch(error => {
        const serverError = createServerError({}, error);
        parseToastServerError(
          {
            title: errorTitle,
            content: `${errorContent} \nError: ${serverError.displayMessage}`
          },
          toast,
          dispatch
        )(error);
      })
      .finally(() => setLoading(false));
}

export default useApiCall;
