import React from 'react';
import PropTypes from 'prop-types';
import { Table, Checkbox } from '@agconnections/grow-ui';
import { formatDateToShortMonthDayYear } from 'utilities/dateHelpers';
import ExpandableLongText from 'components/ExpandableLongText';

const AgVendInvoiceTableRow = ({
  invoice,
  handleSelection,
  selectedInvoices
}) => {
  const isInvoiceSelected = invoiceId => {
    if (!selectedInvoices || selectedInvoices.length === 0) {
      return false;
    }

    return selectedInvoices.findIndex(({ id }) => id === invoiceId) !== -1;
  };

  return (
    <Table.Row>
      <Table.Cell id={`agvend-invoice-table-row-${invoice.id}`}>
        {!invoice.importedAt && (
          <Checkbox
            checked={isInvoiceSelected(invoice.id)}
            onChange={() => {
              handleSelection(invoice);
            }}
          />
        )}
      </Table.Cell>
      <Table.Cell>
        <div className="text-left">{invoice.invoiceNumber}</div>
      </Table.Cell>
      <Table.Cell>
        <div className="text-left">
          {formatDateToShortMonthDayYear(invoice.invoiceDate)}
        </div>
      </Table.Cell>
      <Table.Cell>
        <div className="text-left">{invoice.retailerName}</div>
      </Table.Cell>
      <Table.Cell>
        <div className="text-left">
          <ExpandableLongText
            text={invoice.products?.map(p => p.productName).join(`, `)}
            maxLength={80}
          />
        </div>
      </Table.Cell>
      <Table.Cell>
        {invoice.importedAt && (
          <div className="bg-gray-200 text-neutral-50 font-semibold px-2 rounded-sm">
            {formatDateToShortMonthDayYear(invoice.importedAt)}
          </div>
        )}
      </Table.Cell>
    </Table.Row>
  );
};

AgVendInvoiceTableRow.propTypes = {
  invoice: PropTypes.shape({
    id: PropTypes.string,
    invoiceNumber: PropTypes.string,
    invoiceDate: PropTypes.string,
    retailerName: PropTypes.string,
    products: PropTypes.arrayOf(
      PropTypes.shape({
        productName: PropTypes.string
      })
    ),
    importedAt: PropTypes.string
  }).isRequired,
  handleSelection: PropTypes.func.isRequired,
  selectedInvoices: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string }))
    .isRequired
};

export default AgVendInvoiceTableRow;
