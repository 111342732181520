import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
  useMemo
} from 'react';
import PropTypes from 'prop-types';
import { Card } from '@agconnections/grow-ui';
import { withRouter } from 'react-router-dom';
import { featureCollection } from '@turf/helpers';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ReactMapboxGl from 'react-mapbox-gl';
import mapboxgl, { GeolocateControl } from 'mapbox-gl';
import DrawControl from 'react-mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { parseClientError } from 'helpers/errorHelpers';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import './map.css';

import { ReactComponent as ShareIcon } from 'assets/share.svg';
import DeleteModal from 'components/Modals/DeleteModal';
import { Context } from 'components/Store';
import featureFlagRenderItem from 'helpers/featureFlagRenderItem';
import ClickableDiv from 'components/ClickableDiv';
import drawStyles from 'screens/Property/helpers/drawStyles';
import { flattenDeep } from 'lodash';
import { useAuth } from 'utilities/base-auth';
import useExportPDFMapsActions from 'screens/Property/hooks/useExportPDFMapsActions';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import useMapMouseListeners from './hooks/useMapMouseListeners';
import useDrawHandlers from './hooks/useDrawHandlers';
import PropertyPopup from '../PropertyPopup';

import {
  DEFAULT_FLY_TO_OPTIONS,
  DEFAULT_ZOOM,
  UPDATE_TYPES,
  MAP_HISTORY_FEATURE_TYPES,
  MIN_ZOOM,
  MAX_ZOOM,
  USA_CENTER_COORDINATES
} from '../../../../helpers/constants';
import {
  getFeatureCollectionsFromGeoJSONItems,
  handleShapeDelete,
  handleShapeSubtract,
  handleShapeSplit
} from '../../../../helpers/mapApiHelpers';
import PropertyContext from '../../PropertyMapContext';
import MapKeyPressTool from '../MapKeyPressTool';
import PropertyFeatureLayer from '../PropertyFeatureLayer';
import GrisLayer, { GRIS_TYPES } from '../GrisLayer';

import DrawTools from './DrawTools';
import ObjectMarkerLabel from './ObjectMarkerLabel';

import coordinatesGeocoder from './utilities/coordinatesGeocoder';
import ChangeSummary from './ChangeSummary';
import { calculateCorrectMapHeight } from './utilities/helpers';
import PrintableAreaBoundary from './PrintableAreaBoundary';
import MapControlButton from './MapControlButton';

const AccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const MapboxMap = ReactMapboxGl({
  accessToken: AccessToken,
  minZoom: MIN_ZOOM,
  maxZoom: MAX_ZOOM,
  preserveDrawingBuffer: true
});

const CardWrapper = ({ noCard, children }) => {
  const [{ isMassAssignerActive }] = useContext(Context);
  const mapHeight = calculateCorrectMapHeight(isMassAssignerActive);

  return noCard ? (
    <div className="relative w-full flex flex-grow">{children}</div>
  ) : (
    <Card>
      <Card.Content>
        <div className="relative w-full" style={{ height: mapHeight }}>
          {children}
        </div>
      </Card.Content>
    </Card>
  );
};

const filterSelectedFeatures = features =>
  features.filter(
    ({ properties }) =>
      properties?.$layer === 'selected' && !(properties.$noEdit ?? false)
  );
const filterUnselectedFeatures = collection => {
  return featureCollection(
    collection.features.filter(
      ({ properties }) =>
        properties?.$layer !== 'selected' || !!properties.$noEdit
    )
  );
};
const filterExportPDFMapFeatures = (selectedFields, collection) => {
  const selectedCropZoneIds = flattenDeep(
    selectedFields.map(({ cropzones }) => cropzones.map(({ id }) => id))
  );
  return featureCollection(
    collection.features
      .filter(({ properties }) => {
        if (properties.$landType === 'cropzone') {
          return selectedCropZoneIds.includes(properties.id);
        }
        return true;
      })
      .map(feature => ({
        ...feature,
        properties: {
          ...feature.properties,
          $layer:
            feature.properties.$landType === 'cropzone'
              ? 'focused'
              : feature.properties.$layer,
          sortKey:
            feature.properties.$landType === 'cropzone'
              ? selectedCropZoneIds.indexOf(feature.properties.id) + 1
              : 0
        }
      }))
  );
};

const markFeaturesSelected = (features, layerType = 'selected') =>
  features.map(({ properties, ...feature }) => ({
    ...feature,
    properties: { ...properties, $layer: layerType }
  }));

const isADrawingMode = mode => ['draw_polygon', 'drag_circle'].includes(mode);
const isASelectMode = mode => ['select_only', 'simple_select'].includes(mode);
const unallowedLayerd = layer => ['focused', 'selected'].includes(layer);

const PropertyMap = ({
  currentCoordinates,
  setCurrentCoordinates,
  displayOnly,
  geoJSONCollection,
  customStyles,
  handleDrawCreate,
  handleDrawUpdate,
  handleDrawDelete,
  liveUpdate,
  // markerOn,
  mode,
  modes,
  setMode,
  modeReset,
  zoom,
  setZoom,
  stretch,
  fieldsLoading,
  labelName,
  isCreate
}) => {
  const [mapLoaded, setMapLoaded] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [overlay, setOverlay] = useState(null);
  const [popup, setPopup] = useState(false);
  const [showSplitPopup, setShowSplitPopup] = useState(false);
  const { onMapMouseMoved, onMapMouseDown } = useMapMouseListeners(mode);

  const amplitude = useContext(AmplitudeContext);

  const [
    {
      organization,
      massiveChangeSummary,
      isMassAssignerActive,
      fieldsToMassiveAssign,
      isExportPDFMapsActive,
      exportPDFMapsStep
    },
    dispatch
  ] = useContext(Context);

  const { user } = useAuth();
  const flags = useFlags();

  const { initiateExportFlow } = useExportPDFMapsActions(dispatch);

  const {
    selectedShapeIds,
    setSelectedShapeIds,
    newFeatures,
    mapChangeEvent,
    archiveMapHistoryFeature
  } = useContext(PropertyContext);

  const drawControlRef = useRef(null);
  const lastMapChangeEvent = useRef(new Event('init ref event'));
  const featureIdBeingSplit = useRef();
  const deletedFeatureIds = useRef([]);

  const onEditScreen = !displayOnly;

  const geocoder = new MapboxGeocoder({
    accessToken: AccessToken,
    collapsed: true,
    localGeocoder: coordinatesGeocoder,
    placeholder: 'ZIP, Address, Lat/Long',
    mapboxgl
  });
  const geolocateControl = new GeolocateControl({
    positionOptions: {
      enableHighAccuracy: true
    },
    trackUserLocation: true
  });

  const isStaticMap = isExportPDFMapsActive && exportPDFMapsStep === 1;

  const showExportPDFMapsButton =
    !isExportPDFMapsActive &&
    !isMassAssignerActive &&
    featureFlagRenderItem(
      flags.releaseFePropertyLandingBulkActions,
      flags.releaseFePropertyLandingBulkActionsByOrg,
      organization.id
    ) &&
    flags.experimentFeExportPdfMapsMapControlByUserId.includes(user.id);

  const isGeoJSONLabelEnabled = featureFlagRenderItem(
    flags.releaseFeGeoJsonLayerMapLabel,
    flags.releaseFeGeoJsonLayerMapLabelByOrg,
    organization.id
  );

  const geoJSONFeatureCollections = getFeatureCollectionsFromGeoJSONItems(
    geoJSONCollection.features
  );

  const showDrawControls = !displayOnly || mode === 'select_only';

  const displayOnlyGeoJSONCollection = useMemo(() => {
    if (onEditScreen) {
      return filterUnselectedFeatures(geoJSONCollection);
    }
    if (isExportPDFMapsActive) {
      return filterExportPDFMapFeatures(
        fieldsToMassiveAssign,
        geoJSONCollection
      );
    }
    return featureCollection(geoJSONCollection.features);
  }, [
    onEditScreen,
    isExportPDFMapsActive,
    fieldsToMassiveAssign,
    geoJSONCollection
  ]);

  if (drawControlRef.current && showDrawControls && onEditScreen) {
    geoJSONFeatureCollections.forEach(({ features = [] }) => {
      const filteredFeatures = filterSelectedFeatures(features).map(
        feature => ({
          id: feature.properties.id,
          description: feature.properties.name,
          ...feature
        })
      );
      drawControlRef.current.draw.add(featureCollection(filteredFeatures));
    });
  }

  const inDisplayOnlyJSON = useCallback(
    feature =>
      displayOnlyGeoJSONCollection.features.find(
        ({ id: _id }) => _id === feature.id
      ),
    [displayOnlyGeoJSONCollection]
  );

  const flyToOptions = DEFAULT_FLY_TO_OPTIONS;

  const onStyleLoad = mapReference => {
    if (isStaticMap) {
      mapReference.scrollZoom.disable();
      mapReference.boxZoom.disable();
      mapReference.dragRotate.disable();
      mapReference.dragPan.disable();
      mapReference.keyboard.disable();
      mapReference.doubleClickZoom.disable();
      mapReference.touchZoomRotate.disable();
    } else {
      mapReference.addControl(geocoder, 'bottom-right');
      mapReference.addControl(geolocateControl, 'bottom-right');
    }
    setMapLoaded(true);
  };

  // does the heavy lifting of replacing a new feature with a new instance of the feature with proper id
  const processNewFeature = useCallback(
    (newFeature, callback) => {
      if (newFeature.id && liveUpdate) {
        // Delete the feature with autogenerated id so user can't update wrong feature
        drawControlRef.current.draw.delete(newFeature.id);
      }

      const callbackResults = callback(newFeature);

      if (liveUpdate) drawControlRef.current.draw.add(newFeature); // Insert the feature with the correct id

      return callbackResults;
    },
    [liveUpdate]
  );

  const { onDrawChanged } = useDrawHandlers(
    handleDrawCreate,
    handleDrawUpdate,
    processNewFeature,
    setDeleteModalOpen
  );

  const handleDelete = () => {
    const currentMode = drawControlRef.current.draw.getMode();
    if (currentMode === 'direct_select') {
      drawControlRef.current.draw.trash();
    } else if (currentMode === 'simple_select') {
      setDeleteModalOpen(true);
    }
  };

  const handlePolygonDelete = () => {
    const deletedIds = handleShapeDelete(drawControlRef.current);
    if (!deletedIds) {
      parseClientError(dispatch)('You must select a shape to delete');
    } else {
      handleDrawDelete(deletedIds);
      setSelectedShapeIds([]);
    }
    setDeleteModalOpen(false);
  };

  const handlePolygonUnselect = () => {
    const deletedIds = handleShapeDelete(drawControlRef.current);
    if (deletedIds) {
      handleDrawDelete(deletedIds);
      setSelectedShapeIds([]);
    }
    setDeleteModalOpen(false);
  };

  const handleCloseDeleteModal = () => setDeleteModalOpen(false);

  const handleSelectionChange = () =>
    setSelectedShapeIds(drawControlRef.current.draw.getSelectedIds());

  const handleSubtract = async () => {
    const results = handleShapeSubtract(drawControlRef.current);

    if (!results) {
      parseClientError(dispatch)(
        'You must select a shape completely contained by the other selected shape'
      );
    } else {
      const { added, deleted } = results;
      await handleDrawDelete(deleted);
      setSelectedShapeIds([]);
      processNewFeature(added, newFeature => {
        handleDrawCreate([newFeature]);
      });
    }
  };

  const handleSplitStart = () => {
    // eslint-disable-next-line prefer-destructuring
    featureIdBeingSplit.current = selectedShapeIds[0];
    setMode('draw_line_string');
  };

  const handleSplit = async lineFeature => {
    const { added, deleted } = handleShapeSplit(
      drawControlRef.current,
      featureIdBeingSplit.current,
      lineFeature
    );
    if (!added) {
      parseClientError(dispatch)(
        'The split could not be performed as requested.'
      );
    } else {
      handleDrawDelete([featureIdBeingSplit.current]);
      deletedFeatureIds.current = deletedFeatureIds.current.concat(deleted);
      const addedFeatures = markFeaturesSelected(added.features);

      handleDrawCreate(addedFeatures, deleted);
    }
    featureIdBeingSplit.current = null;
    modeReset();
  };

  const handleImportShape = useCallback(
    ({ feature }) => {
      // processNewFeature(feature, newFeature => {
      //   handleDrawCreate([newFeature]);
      // });
      //
      drawControlRef.current.draw.add(feature); // Insert the feature with the correct id
      const addedFeatures = markFeaturesSelected([feature], 'focused');
      handleDrawCreate(addedFeatures);
    },
    [handleDrawCreate]
  );

  const handleCreate = draw => {
    const currentMode = drawControlRef.current.draw.getMode();

    if (currentMode === 'draw_line_string') {
      // only using the draw line mode for the split command
      const [lineFeature] = draw.features;
      handleSplit(lineFeature);
    } else {
      const tmpFeatures = onEditScreen
        ? markFeaturesSelected(draw.features)
        : draw.features;

      const mappedFeatures = tmpFeatures.map(ft => {
        if (ft.properties.isCircle) {
          const { $layer, center } = ft.properties;

          return {
            ...ft,
            properties: {
              $layer,
              center
            }
          };
        }

        return ft;
      });
      onDrawChanged(mappedFeatures, UPDATE_TYPES.CREATE);
      // modeReset();
    }
  };

  const canDraw = useMemo(
    () =>
      isCreate &&
      isASelectMode(mode) &&
      !overlay &&
      !geoJSONCollection.features.some(feature =>
        unallowedLayerd(feature.properties.$layer)
      ),
    [isCreate, overlay, mode, geoJSONCollection.features]
  );

  const showPopup = () => {
    if (canDraw) {
      setPopup(true);
    }
  };

  const cannotSplit = useMemo(
    () =>
      geoJSONCollection.features.some(feature =>
        unallowedLayerd(feature.properties.$layer)
      ) && selectedShapeIds.length !== 1,
    [geoJSONCollection.features, selectedShapeIds]
  );

  const showSplitToolPopup = hide => {
    if (cannotSplit && !hide) {
      setShowSplitPopup(true);
    } else {
      setShowSplitPopup(false);
    }
  };

  const onClickExportButton = () => {
    amplitude.sendEventToAmplitude(
      amplitude.events.epic.Properties.accessPropertyMaps,
      {
        from: 'top right of map'
      }
    );
    initiateExportFlow();
  };

  // detects when changes are made to the includeOnMaps property in geoJSONCollection
  useEffect(() => {
    // We should pass only as a geoJSONCollection in the future as a prop to LandMap in this way
    if (
      !displayOnly &&
      geoJSONCollection.features.length &&
      drawControlRef.current
    ) {
      const drawnFeatures = drawControlRef.current.draw.getAll();
      geoJSONCollection.features.forEach(feature => {
        // do not include features that have been explicitly excluded or permanently deleted
        const includeOnMaps =
          (!feature.properties || feature.properties.includeOnMaps !== false) &&
          !deletedFeatureIds.current.includes(feature.id);
        const drawnFeature = drawnFeatures.features.find(drawn => {
          return drawn.id === feature.id;
        });

        if (includeOnMaps && !drawnFeature && !inDisplayOnlyJSON(feature)) {
          // add feature if not drawn but should be included on map
          drawControlRef.current.draw.add(feature);
        } else if (!includeOnMaps && drawnFeature) {
          // remove feature if drawn but should not be included on map
          drawControlRef.current.draw.delete(drawnFeature.id);
        }
      });
    }
  }, [geoJSONCollection, displayOnly, inDisplayOnlyJSON]);

  useEffect(() => {
    if (isASelectMode(mode) && mapLoaded && drawControlRef.current?.draw) {
      drawControlRef.current.draw.changeMode(mode, {
        featureIds: selectedShapeIds || []
      });
    }
  }, [mode, mapLoaded, selectedShapeIds]);

  useEffect(() => {
    if (
      !displayOnly &&
      mode !== 'select_only' &&
      mapLoaded &&
      drawControlRef.current?.draw &&
      drawControlRef.current.draw.getMode() !== mode
    ) {
      drawControlRef.current.draw.changeMode(mode);
    }
  }, [mode, mapLoaded, drawControlRef, displayOnly]);

  // process any new features added to the mapHistory, then archive it
  // mapChangeEvent only changes when new features are ready to be processed
  useEffect(() => {
    if (
      mapChangeEvent.timeStamp !== lastMapChangeEvent.current.timeStamp &&
      newFeatures.length > 0
    ) {
      lastMapChangeEvent.current = mapChangeEvent;
      newFeatures.forEach(newFeature => {
        if (newFeature.featureType === MAP_HISTORY_FEATURE_TYPES.IMPORT) {
          handleImportShape(newFeature);
          archiveMapHistoryFeature(newFeature);
        }
        if (newFeature.featureType === MAP_HISTORY_FEATURE_TYPES.ADD) {
          const addedFeatures = markFeaturesSelected([newFeature.feature]);
          handleDrawCreate(addedFeatures);
          archiveMapHistoryFeature(newFeature);
        }
        if (newFeature.featureType === MAP_HISTORY_FEATURE_TYPES.REMOVE) {
          handleDrawDelete([newFeature.feature.id]);
          archiveMapHistoryFeature(newFeature);
        }
      });
    }
  }, [
    mapChangeEvent,
    newFeatures,
    handleImportShape,
    handleDrawCreate,
    handleDrawDelete,
    archiveMapHistoryFeature
  ]);

  const handleNewFeature = newShapes => {
    setSelectedShapeIds(newShapes.map(shape => shape.feature.id));
  };

  return (
    <ClickableDiv
      data-testid="land-map"
      className={`${isStaticMap ? 'static-map cursor-pointer' : ''} ${
        stretch ? 'flex-grow flex-col flex' : 'h-full'
      }`}
      onMouseEnter={() => showPopup()}
      onMouseLeave={() => setPopup(false)}
    >
      <CardWrapper noCard={stretch}>
        <PropertyPopup
          position="center"
          ariaLabel="Click to split"
          msg="To use the split tool, please select the shape you want to split on the map, one at a time"
          showPopup={showSplitPopup}
        />
        {popup && (
          <PropertyPopup
            position="center"
            ariaLabel="Click to draw"
            msg="Click to start drawing a shape"
            showPopup={popup}
          />
        )}
        {isMassAssignerActive && (
          <ChangeSummary massiveChangeSummary={massiveChangeSummary} />
        )}
        {showExportPDFMapsButton && (
          <MapControlButton
            data-testid="export-pdf-maps-map-control"
            className="absolute py-10px px-4"
            style={{ top: 72, right: 20 }}
            onClick={onClickExportButton}
          >
            <ShareIcon className="mr-1" />
            <span
              style={{ letterSpacing: '-0.105px' }}
              className="leading-5 ml-1px"
            >
              Export PDF Map
            </span>
          </MapControlButton>
        )}
        {!isStaticMap && (
          <DrawTools
            modeSelected={setMode}
            mode={mode}
            onDelete={handleDelete}
            onSubtract={handleSubtract}
            onSplit={handleSplitStart}
            setCurrentCoordinates={setCurrentCoordinates}
            setZoom={setZoom}
            overlay={overlay}
            setOverlay={setOverlay}
            features={geoJSONCollection.features}
            setSelectedShapeIds={setSelectedShapeIds}
            showSplitToolPopup={showSplitToolPopup}
          />
        )}
        {isExportPDFMapsActive && exportPDFMapsStep === 0 && (
          <PrintableAreaBoundary />
        )}
        <MapboxMap
          // eslint-disable-next-line react/style-prop-object
          style="mapbox://styles/mapbox/satellite-streets-v11"
          zoom={zoom}
          center={currentCoordinates || USA_CENTER_COORDINATES}
          flyToOptions={flyToOptions}
          containerStyle={{
            height: '100%',
            width: '100%'
          }}
          onStyleLoad={onStyleLoad}
          onMouseMove={(_, event) => {
            if (!displayOnly && drawControlRef.current?.draw) {
              const currentMode = drawControlRef.current.draw.getMode();
              if (isADrawingMode(currentMode))
                onMapMouseMoved(event, drawControlRef);
            }
          }}
          onMouseDown={(_, event) => {
            if (!displayOnly && drawControlRef.current?.draw) {
              const currentMode = drawControlRef.current.draw.getMode();
              if (isADrawingMode(currentMode)) onMapMouseDown(event);
            }
          }}
          onMouseUp={() => {
            if (canDraw) {
              setMode('draw_polygon');
            }
          }}
        >
          <PropertyFeatureLayer
            key={`map-feature-with-label:${isGeoJSONLabelEnabled}`}
            geoJSON={displayOnlyGeoJSONCollection}
            customStyles={customStyles}
            setZoom={setZoom}
            visible={displayOnly || onEditScreen}
            activeObjectType={labelName}
            isGeoJSONLabelEnabled={isGeoJSONLabelEnabled}
          />
          {overlay === 'CLU' && (
            <GrisLayer
              type={GRIS_TYPES.CLU}
              setZoom={setZoom}
              geoJSONCollection={geoJSONCollection}
              onChange={handleNewFeature}
              onDelete={handlePolygonUnselect}
            />
          )}
          {overlay === 'SAT' && (
            <GrisLayer
              type={GRIS_TYPES.SATELLITE}
              setZoom={setZoom}
              geoJSONCollection={geoJSONCollection}
              onChange={handleNewFeature}
              overlay={overlay}
              onDelete={handlePolygonUnselect}
            />
          )}

          {showDrawControls && (
            <DrawControl
              ref={drawControl => {
                drawControlRef.current = drawControl;
              }}
              onDrawCreate={handleCreate}
              onDrawDelete={draw => {
                onDrawChanged(draw.features, UPDATE_TYPES.DELETE);
              }}
              onDrawUpdate={draw => {
                onDrawChanged(draw.features, UPDATE_TYPES.UPDATE);
              }}
              onDrawSelectionChange={handleSelectionChange}
              defaultMode="simple_select"
              displayControlsDefault={false}
              modes={modes}
              styles={drawStyles}
            />
          )}
          {!fieldsLoading && !isGeoJSONLabelEnabled && (
            <ObjectMarkerLabel
              geoCollection={geoJSONCollection}
              activeObjectType={labelName}
            />
          )}
          <MapKeyPressTool onDelete={handleDelete} setMode={setMode} />
        </MapboxMap>
        <DeleteModal
          open={deleteModalOpen && !displayOnly}
          itemType="Shape"
          onDelete={handlePolygonDelete}
          onCancel={handleCloseDeleteModal}
        />
      </CardWrapper>
    </ClickableDiv>
  );
};

CardWrapper.propTypes = {
  noCard: PropTypes.bool,
  children: PropTypes.node.isRequired
};

CardWrapper.defaultProps = {
  noCard: false
};

PropertyMap.defaultProps = {
  currentCoordinates: null,
  setCurrentCoordinates: () => {},
  displayOnly: false,
  geoJSONCollection: { features: [], type: 'FeatureCollection' },
  customStyles: null,
  handleDrawCreate: () => {},
  handleDrawUpdate: () => {},
  handleDrawDelete: () => {},
  // markerOn: false,
  liveUpdate: true,
  history: null,
  zoom: DEFAULT_ZOOM,
  setZoom: () => {},
  stretch: false,
  labelName: '',
  isCreate: false
};

PropertyMap.propTypes = {
  currentCoordinates: PropTypes.arrayOf(PropTypes.number),
  setCurrentCoordinates: PropTypes.func,
  displayOnly: PropTypes.bool,
  geoJSONCollection: PropTypes.shape({
    features: PropTypes.arrayOf(PropTypes.object),
    type: PropTypes.string
  }),
  customStyles: PropTypes.objectOf(
    PropTypes.shape({
      fillColor: PropTypes.string,
      fillOutlineColor: PropTypes.string
    })
  ),
  handleDrawCreate: PropTypes.func,
  handleDrawUpdate: PropTypes.func,
  handleDrawDelete: PropTypes.func,
  mode: PropTypes.string.isRequired,
  modeReset: PropTypes.func.isRequired,
  modes: PropTypes.objectOf(PropTypes.object).isRequired,
  setMode: PropTypes.func.isRequired,
  liveUpdate: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  zoom: PropTypes.arrayOf(PropTypes.number),
  setZoom: PropTypes.func,
  stretch: PropTypes.bool,
  labelName: PropTypes.string,
  fieldsLoading: PropTypes.bool.isRequired,
  isCreate: PropTypes.bool
};

export default React.memo(withRouter(PropertyMap));
