import React from 'react';
import PropTypes from 'prop-types';
import ExportPDFMapsFooter from '../../../PanelFooter/components/ExportPDFMapsFooter';
import CropColorsSection from './components/CropColorsSection';
import MapLabelsSection from './components/MapLabelsSection';
import FooterLabelsSelection from './components/FooterLabelsSelection';

const ExportPDFMapsOptionsForm = ({ selectedFields }) => {
  return (
    <div className="relative flex flex-col flex-grow">
      <div className="flex flex-col flex-grow overflow-y-auto border-t border-neutral-20">
        <CropColorsSection selectedFields={selectedFields} />
        <MapLabelsSection />
        <FooterLabelsSelection />
      </div>
      <ExportPDFMapsFooter step={1} selectedFields={selectedFields} />
    </div>
  );
};
ExportPDFMapsOptionsForm.propTypes = {
  selectedFields: PropTypes.arrayOf.isRequired
};

export default ExportPDFMapsOptionsForm;
